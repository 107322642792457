import { login, refreshToken, logout } from '@/api/login'
import router from '@/router'
import jwtDecode from 'jwt-decode'

export default {
    namespaced: true,
    state: {
        user:null,
        userRol:null,
        userId: null,
        token: null,
        refreshToken: "",
        isAuthenticated: false, 
        loginError: false
    },
    mutations: {
        setUser(state, username){
            state.user = username
        },
        LogOut(state){
            state.user = null
        },
        setAccessToken: function(state, token) {
            state.token = token;
          },   
        setRefreshToken: function(state, refreshToken) {
            state.refreshToken = refreshToken;
        },
        setAuthenticated: function(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated
        },
        setLoginError: function(state, loginError) {
            state.loginError = loginError
        },
        setUser: function(state, user) {
            state.user = user
        },         
        setUserRol: function(state, userRol) {
            state.userRol = userRol
        },
        setuserId: function(state, userId) {
            state.userId = userId
        },
    },
    actions: {
        async login({ commit }, userc) {

            try {
                let response = await login(userc)
                // if (response) {
                    
                commit('setAccessToken', response.access)
                commit('setRefreshToken', response.refresh)
                // let tmus = jwtDecode(response.access)
             
                if (response.access && response.refresh) {
                    commit('setAuthenticated', true)
                    commit('setLoginError', false)
                }
              
                  router.replace("/tablero")
                // }

                // }
            } catch (e) {
               // console.log('catch erroro login')
               // console.log(e)
                //     commit('setAccessToken', false)
                //     commit('setRefreshToken', false)
                //     commit('setAuthenticated', false)
                //     commit('setLoginError', true)
                //     return Promise.reject(e)
            }
        },       
        refreshToken: async ({ state, commit }) => {
            const { token } = await refreshToken( { token: state.refreshToken })
            if(token) {
                commit("setAccessToken", response.data.accessToken)
            }
        },
        setAccessToken({ commit }, token) {
            commit('setAccessToken', token)
        },
        validateAuthentication: async ({state, commit}) => {
            const accessToken = localStorage.getItem('dgp-auth-access-token')
            const refreshToken = localStorage.getItem('dgp-auth-refresh-token')
            if (accessToken && refreshToken) {
                commit('setAuthenticated', true)
            }
        },
        logout: async ({state, commit}) => {
            localStorage.removeItem('dgp-auth-refresh-token')
            localStorage.removeItem('dgp-auth-access-token')
            commit('setAuthenticated', false)
        }
    },
    getters: {
        isAuthenticated: state => !!state.isAuthenticated,    
        StateUser: state => state.user,
        StateRol: state => state.userRol,
    }
}