define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.datacam = [        
        /*
        {
           "anio":2000,
           "CustomerID":"VINET",
           "OrderDate":"235",
           "ShippedDate":"4535",
           "Freight":"1,426",
           "Freight2":" - ",
           "Freight3":"1,761",
           "ShipName":"Vins et alcools Chevalier",
           "ShipAddress":"59 rue de l'Abbaye",
           "ShipCity":"Reims",
           "ShipRegion":null,
           "ShipCountry":"326",
           "ShipCountry2":"1,795",
           "ShipCountry3":"40,488",
           "ShipCountry4":"3,134",
           "ShipCountry5":"-",
           "ShipCountry6":"45,423",
           "ShipCountry7":"2,531",
           "ShipCountry8":"22,443",
           "ShipCountry9":"35,172",
           "ShipCountry10":"962",
           "ShipCountry11":"-",
           "ShipCountry12":"61,108",
           "Verified": true
        },
        {
         "anio":2001,
         "CustomerID":"VINET",
         "OrderDate":"450",
         "ShippedDate":"3,041",
         "Freight":"3,041",
         "Freight2":"-",
         "Freight3":"2,662",
         "ShipName":"Vins et alcools Chevalier",
         "ShipAddress":"59 rue de l'Abbaye",
         "ShipCity":"Reims",
         "ShipRegion":null,
         "ShipCountry":"137",
         "ShipCountry2":"1,795",
         "ShipCountry3":"40,402",
         "ShipCountry4":"3,310",
         "ShipCountry5":"-",
         "ShipCountry6":"45,823",
         "ShipCountry7":"2,458",
         "ShipCountry8":"21,752",
         "ShipCountry9":"35,021",
         "ShipCountry10":"962",
         "ShipCountry11":"-",
         "ShipCountry12":"61,108",
         "Verified": true
        },
        {
            "anio":2002,
            "CustomerID":"VINET",
            "OrderDate":"330",
            "ShippedDate":"1,140",
            "Freight":"1,140",
            "Freight2":"-",
            "Freight3":"3,470",
            "ShipName":"Vins et alcools Chevalier",
            "ShipAddress":"59 rue de l'Abbaye",
            "ShipCity":"Reims",
            "ShipRegion":null,
            "ShipCountry":"185",
            "ShipCountry2":"1,795",
            "ShipCountry3":"40,323",
            "ShipCountry4":"3,506",
            "ShipCountry5":"-",
            "ShipCountry6":"45,808",
            "ShipCountry7":"2,230",
            "ShipCountry8":"21,866",
            "ShipCountry9":"36,678",
            "ShipCountry10":"962",
            "ShipCountry11":"-",
            "ShipCountry12":"61,736",
            "Verified": true
           },
           {
            "anio":2003,
            "CustomerID":"VINET",
            "OrderDate":"573",
            "ShippedDate":"4,095",
            "Freight":"4,095",
            "Freight2":"-",
            "Freight3":"4,668",
            "ShipName":"Vins et alcools Chevalier",
            "ShipAddress":"59 rue de l'Abbaye",
            "ShipCity":"Reims",
            "ShipRegion":null,
            "ShipCountry":"153",
            "ShipCountry2":"1,827",
            "ShipCountry3":"40,227",
            "ShipCountry4":"3,099",
            "ShipCountry5":"-",
            "ShipCountry6":"45,286",
            "ShipCountry7":"2,230",
            "ShipCountry8":"21,866",
            "ShipCountry9":"37,121",
            "ShipCountry10":"781",
            "ShipCountry11":"-",
            "ShipCountry12":"61,998",
            "Verified": true
           },
           {
            "anio":2004,
            "CustomerID":"VINET",
            "OrderDate":"1,222",
            "ShippedDate":"4,095",
            "Freight":"4,095",
            "Freight2":"-",
            "Freight3":"6,294",
            "ShipName":"Vins et alcools Chevalier",
            "ShipAddress":"59 rue de l'Abbaye",
            "ShipCity":"Reims",
            "ShipRegion":null,
            "ShipCountry":"-",
            "ShipCountry2":"-",
            "ShipCountry3":"37,929",
            "ShipCountry4":"2,420",
            "ShipCountry5":"-",
            "ShipCountry6":"40,349",
            "ShipCountry7":"746",
            "ShipCountry8":"12,677",
            "ShipCountry9":"41,868",
            "ShipCountry10":"545",
            "ShipCountry11":"226",
            "ShipCountry12":"56,062",
            "Verified": true
           },
        {
            "anio":2005,
            "CustomerID":"VINET",
            "OrderDate":"1,226",
            "ShippedDate":"5,072",
            "Freight":"5,072",
            "Freight2":"-",
            "Freight3":"6,294",
            "ShipName":"Vins et alcools Chevalier",
            "ShipAddress":"59 rue de l'Abbaye",
            "ShipCity":"Reims",
            "ShipRegion":null,
            "ShipCountry":"-",
            "ShipCountry2":"-",
            "ShipCountry3":"37,929",
            "ShipCountry4":"2,420",
            "ShipCountry5":"-",
            "ShipCountry6":"40,349",
            "ShipCountry7":"746",
            "ShipCountry8":"12,677",
            "ShipCountry9":"41,868",
            "ShipCountry10":"545",
            "ShipCountry11":"226",
            "ShipCountry12":"56,062",
            "Verified": true
        },
        {
            "anio":2006,
            "CustomerID":"VINET",
            "OrderDate":"1,206",
            "ShippedDate":"5,150",
            "Freight":"5,150",
            "Freight2":"-",
            "Freight3":"6,356",
            "ShipName":"Vins et alcools Chevalier",
            "ShipAddress":"59 rue de l'Abbaye",
            "ShipCity":"Reims",
            "ShipRegion":null,
            "ShipCountry":"-",
            "ShipCountry2":"-",
            "ShipCountry3":"38,213",
            "ShipCountry4":"2,801",
            "ShipCountry5":"-",
            "ShipCountry6":"40,014",
            "ShipCountry7":"827",
            "ShipCountry8":"12,205",
            "ShipCountry9":"45,265",
            "ShipCountry10":"626",
            "ShipCountry11":"335",
            "ShipCountry12":"59,258",
            "Verified": true
        },
        {
            "anio":2007,
            "CustomerID":"VINET",
            "OrderDate":"1,183",
            "ShippedDate":"5,211",
            "Freight":"5,211",
            "Freight2":"-",
            "Freight3":"6,394",
            "ShipName":"Vins et alcools Chevalier",
            "ShipAddress":"59 rue de l'Abbaye",
            "ShipCity":"Reims",
            "ShipRegion":null,
            "ShipCountry":"-",
            "ShipCountry2":"-",
            "ShipCountry3":"38,457",
            "ShipCountry4":"2,954",
            "ShipCountry5":"-",
            "ShipCountry6":"41,411",
            "ShipCountry7":"844",
            "ShipCountry8":"11,286",
            "ShipCountry9":"47,264",
            "ShipCountry10":"789",
            "ShipCountry11":"341",
            "ShipCountry12":"60,984",
            "Verified": true
        },
        {
            "anio":2008,
            "CustomerID":"VINET",
            "OrderDate":"1,200",
            "ShippedDate":"5,186",
            "Freight":"5,186",
            "Freight2":"-",
            "Freight3":"6,388",
            "ShipName":"Vins et alcools Chevalier",
            "ShipAddress":"59 rue de l'Abbaye",
            "ShipCity":"Reims",
            "ShipRegion":null,
            "ShipCountry":"-",
            "ShipCountry2":"-",
            "ShipCountry3":"38,537",
            "ShipCountry4":"3,116",
            "ShipCountry5":"-",
            "ShipCountry6":"41,765",
            "ShipCountry7":"731",
            "ShipCountry8":"11,397",
            "ShipCountry9":"48,506",
            "ShipCountry10":"776",
            "ShipCountry11":"254",
            "ShipCountry12":"61,984",
            "Verified": true
        },
        */
    ];
});