import { render, staticRenderFns } from "./DatosBeneficiario.vue?vue&type=template&id=5eb41da6&scoped=true"
import script from "./DatosBeneficiario.vue?vue&type=script&lang=js"
export * from "./DatosBeneficiario.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb41da6",
  null
  
)

export default component.exports