<template>
<div class="row">
  <div class="col-md-12">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active" id="tabPersonal">
          <a href="#catmatricula" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
            Matricula
          </a>
        </li>
        <li role="presentation"  id="tabcataeropuerto">
          <a href="#cataeropuerto" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Aeropuerto
          </a>
        </li>
        <li role="presentation"  id="tabcatcaracteristica">
          <a href="#catcaracteristica" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Caracteristicas
          </a>
        </li>
        <li role="presentation"  id="tabcatpropietario">
          <a href="#catpropietario" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Propietario
          </a>
        </li>
        <li role="presentation"  id="tabcatindicadores">
          <a href="#catindicadores" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Indicadores
          </a>
        </li>
        <li role="presentation"  id="tabcatingegr">
          <a href="#catingegr" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Ingresos/Egresos
          </a>
        </li>
        <li role="presentation"  id="tabcatlareas">
          <a href="#catlareas" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Lineas Aereas
          </a>
        </li>
        <li role="presentation"  id="tabcatregiones">
          <a href="#catregiones" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
            Regiones
          </a>
        </li>
        <li role="presentation"  id="tabcattpaviacion">
          <a href="#cattpaviacion" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Tipo de Aviacion
          </a>
        </li>
        <li role="presentation"  id="tabcatpersonala">
          <a href="#catpersonala" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Personal Aereo
          </a>
        </li>
        <li role="presentation"  id="tabcatcentrored">
          <a href="#catcentrored" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Centro/red
          </a>
        </li>
    </ul>
    <div class="tab-content">
        <div role="tabpanel" class="tab-pane active" id="catmatricula">
          <catmatricula  @set-icveEdo="setCEdo" @show-error="showError" 
            :camino_id.sync="camino_id"/>
        </div>
        <div role="tabpanel" class="tab-pane" id="cataeropuerto">
          <cataeropuerto  @show-error="showError"> </cataeropuerto>
        </div>
        <div role="tabpanel" class="tab-pane" id="catcaracteristica">
          <catcaracteristica  @show-error="showError"> </catcaracteristica>
        </div>
        <div role="tabpanel" class="tab-pane" id="catpropietario">
          <catpropietario  @show-error="showError"> </catpropietario>
        </div>
        <div role="tabpanel" class="tab-pane" id="catindicadores">
          <catindicadores  @show-error="showError"> </catindicadores>
        </div>
        <div role="tabpanel" class="tab-pane" id="catingegr">
          <catingegr  @show-error="showError"> </catingegr>
        </div>
        <div role="tabpanel" class="tab-pane" id="catlareas">
          <catlareas  @show-error="showError"> </catlareas>
        </div>
        <div role="tabpanel" class="tab-pane" id="catregiones">
          <catregiones  @show-error="showError"> </catregiones>
        </div>
        <div role="tabpanel" class="tab-pane" id="cattpaviacion">
          <cattpaviacion  @show-error="showError"> </cattpaviacion>
        </div>
        <div role="tabpanel" class="tab-pane" id="catpersonala">
          <catpersonala  @show-error="showError"> </catpersonala>
        </div> 
        <div role="tabpanel" class="tab-pane" id="catcentrored">
          <catcentrored  @show-error="showError"> </catcentrored>
        </div>  
               
    </div>
  <div>
    <div v-if="msgError" class="alert alert-danger">{{ msgError }}</div>
  </div>    
  </div>
</div>
</template>

<script>

import catmatricula from '@/components/catTAA/catmatricula';
import cataeropuerto from '@/components/catTAA/cataeropuerto';
import catcaracteristica from '@/components/catTAA/catcaracteristica';
import catpropietario from '@/components/catTAA/catpropietario';
import catindicadores from '@/components/catTAA/catindicadores';
import catingegr from '@/components/catTAA/catingegr';
import catlareas from '@/components/catTAA/catlareas';
import catregiones from '@/components/catTAA/catregiones';
import { mapMutations } from 'vuex'

export default {
  name: 'catTAA',
  components: {catmatricula,
               cataeropuerto,
               catcaracteristica,
               catpropietario,
               catindicadores,
               catingegr,
               catlareas,
               catregiones
              },
  data () {
    return {
        camino_id:0,
        cEstado: '',
        msgError: null,
        breadcrumb: ['Puente '+ this.$route.params.obraId],
    }    
  },
  methods: {
    setCEdo (e) {
      const obj = JSON.parse(JSON.stringify(e))
      this.cEstado = obj.datos
    },
    showError(e){
      console.log('show-error')
      this.msgError = e;
      console.log(e)
      //setTimeout(() => this.msgError = false, 10000);
    },
    ...mapMutations(['setBreadcrumb']),    
  },
  computed: {
      getCaminoId(){
          return this.camino_id
      }
  },
  created() {
  },
  beforeMount: function () {    
    if(this.$route.params.obraId){
      this.setBreadcrumb(this.breadcrumb)
    }
  }, 
}
</script>



