import axios from 'axios'
import store from '@/store/'
import { Message } from 'element-ui'
import createAuthRefreshInterceptor from "axios-auth-refresh"

const service = axios.create({
  baseURL: process.env.VUE_APP_AUTH_URL, 
  timeout: 5000, 
})

//refresh
const refreshAuthLogic = failedRequest => {
    if (getRefreshToken() != '')
        return service.post('/refresh', {
            refresh: getRefreshToken()
        }).then(tokenRefreshResponse => {
            store.dispatch('login/setAccessToken', tokenRefreshResponse.accessToken)
            failedRequest.response.config.headers["Authorization"] = 'Bearer ' + tokenRefreshResponse.accessToken
        return Promise.resolve()
        })
    
    return Promise.resolve()
} 

const statusCodes = {
  statusCodes: [ 401, 403 ] // default: [ 401 ]
}
createAuthRefreshInterceptor(service, refreshAuthLogic, statusCodes)


function getRefreshToken(){
  const refreshToken = JSON.parse(localStorage.getItem('dgp-auth-refresh-token'))
  if(refreshToken) return refreshToken
  return ''
}

// request interceptor
function getAccessToken() {
    const accessToken = JSON.parse(localStorage.getItem('dgp-auth-access-token'))
    if(accessToken){
        return accessToken
    }         
    return ''
}

function clearAccessToken() {
    if (localStorage.getItem('dgp-auth-access-token')) 
        localStorage.removeItem('dgp-auth-access-token')
}

function clearRefreshToken() {
    if (localStorage.getItem('dgp-auth-refresh-token')) 
        localStorage.removeItem('dgp-auth-refresh-token')
}

function saveAccessToken(token) {
    if (!localStorage.getItem('dgp-auth-access-token') && token && token != '')
        localStorage.setItem('dgp-auth-access-token', token)
}

function saveRefreshToken(token) {
    if (!localStorage.getItem('dgp-auth-refresh-token') && token && token != '')
        localStorage.setItem('dgp-auth-refresh-token', token)
}


service.interceptors.request.use(request => {
    const token = getAccessToken()
    if (token && token != '')
        request.headers['Authorization'] = "Bearer " + getAccessToken()

    return request
})


// response interceptor
service.interceptors.response.use(
    response => {
        if (response.status === 200  || response.status === 201  || response.status === 202  || response.status === 204) {
            if (response.data.access && response.data.refresh) {
                saveAccessToken(response.data.access)
                saveRefreshToken(response.data.refresh)
            }
            return Promise.resolve(response.data)
        } else {    
          return Promise.reject()                    
        }
    },
    error => {
        let { response, message } = error

        if (response.status == 401) {
            clearAccessToken()
           clearRefreshToken()
        }
        if (error.response && error.response.data) {
          const { status, data } = response
         
         // console.log(message + ' - ' +  status + ' ' + data)
          Message({
            message: 'No se pudo Iniciar Sesión. Verifique sus credenciales.',
            type: 'error', 
            duration: 5 * 1000
          })        
          return Promise.reject(data)
        } else {

          if (message === 'Network Error') {
            message = 'Network Error'
          }
          if (message.includes('timeout')) {
            message = 'timeout'
          }
          if (message.includes('Request failed with status code')) {
            const code = message.substr(message.length - 3)
            message = 'Backend interface' + code + 'Exception'
          }
         clearAccessToken()
         clearRefreshToken()
          Message({
            message: 'Ocurrio un error al realizar la petición',
            type: 'error',
            duration: 5 * 1000
          })          
          return Promise.reject(message)
        }
    }
  )

export default service