<template>
<div class="row">
  <div class="col-md-12">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active" id="tabPersonal">
          <a href="#cattpred" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
            Tipo de red
          </a>
        </li>
        <li role="presentation"  id="tabcatautopista">
          <a href="#catautopista" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Autopista
          </a>
        </li>
        <li role="presentation"  id="tabcatpuentes">
          <a href="#catpuentes" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Puentes
          </a>
        </li>
        <li role="presentation"  id="tabcatredoperada">
          <a href="#catredoperada" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Red Operada
          </a>
        </li>
        <li role="presentation"  id="tabcatcarriles">
          <a href="#catcarriles" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Carriles
          </a>
        </li>
        <li role="presentation"  id="tabcatclaseveh">
          <a href="#catclaseveh" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Clase de Vehiculos
          </a>
        </li>
        <li role="presentation"  id="tabcattipoveh">
          <a href="#cattipoveh" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
            Tipo de vehiculos
          </a>
        </li>
        <li role="presentation"  id="tabcatrangos">
          <a href="#catrangos" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Rangos
          </a>
        </li>
        <li role="presentation"  id="tabcatejestroncales">
          <a href="#catejestroncales" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Ejes Troncales
          </a>
        </li>
        <li role="presentation"  id="tabcattipocarreteras">
          <a href="#cattipocarreteras" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Tipo de Carreteras
          </a>
        </li>
        <li role="presentation"  id="tabcatprogramas">
          <a href="#catprogramas" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Programas
          </a>
        </li>
        <li role="presentation"  id="tabcatdependencias">
          <a href="#catdependencias" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Dependencias
          </a>
        </li>
    </ul>
    <div class="tab-content">
        <div role="tabpanel" class="tab-pane active" id="cattpred">
          <cattpred  @set-icveEdo="setCEdo" @show-error="showError" 
            :camino_id.sync="camino_id"/>
        </div>
        <div role="tabpanel" class="tab-pane" id="catautopista">
          <catautopista  @show-error="showError"> </catautopista>
        </div>
        <div role="tabpanel" class="tab-pane" id="catpuentes">
          <catpuentes  @show-error="showError"> </catpuentes>
        </div>
        <div role="tabpanel" class="tab-pane" id="catredoperada">
          <catredoperada  @show-error="showError"> </catredoperada>
        </div>
        <div role="tabpanel" class="tab-pane" id="catcarriles">
          <catcarriles  @show-error="showError"> </catcarriles>
        </div>
        <div role="tabpanel" class="tab-pane" id="catclaseveh">
          <catclaseveh  @show-error="showError"> </catclaseveh>
        </div>
        <div role="tabpanel" class="tab-pane" id="cattipoveh">
          <cattipoveh  @show-error="showError"> </cattipoveh>
        </div>
        <div role="tabpanel" class="tab-pane" id="catrangos">
          <catrangos  @show-error="showError"> </catrangos>
        </div>
        <div role="tabpanel" class="tab-pane" id="catejestroncales">
          <catejestroncales  @show-error="showError"> </catejestroncales>
        </div>
        <div role="tabpanel" class="tab-pane" id="cattipocarreteras">
          <cattipocarreteras  @show-error="showError"> </cattipocarreteras>
        </div> 
        <div role="tabpanel" class="tab-pane" id="catprogramas">
          <catprogramas  @show-error="showError"> </catprogramas>
        </div>  
        <div role="tabpanel" class="tab-pane" id="catdependencias">
          <catdependencias  @show-error="showError"> </catdependencias>
        </div>
               
    </div>
  <div>
    <div v-if="msgError" class="alert alert-danger">{{ msgError }}</div>
  </div>    
  </div>
</div>
</template>

<script>
import cattpred from '@/components/catIC/cattpred';
import catautopista from '@/components/catIC/catautopista';
import catpuentes from '@/components/catIC/catpuentes';
import catredoperada from '@/components/catIC/catredoperada';
import catcarriles from '@/components/catIC/catcarriles';
import catclaseveh from '@/components/catIC/catclaseveh';
import cattipoveh from '@/components/catIC/cattipoveh';
import catrangos from '@/components/catIC/catrangos';
import catejestroncales from '@/components/catIC/catejestroncales';
import cattipocarreteras from '@/components/catIC/cattipocarreteras';
import catdependencias from '@/components/catIC/catdependencias';
import catprogramas from '@/components/catIC/catprogramas';
/* import DatosGeograficosPuentes from '@/components/AltaDePuentes/DatosGeograficosPuentes';
import DatosPuentes from '@/components/AltaDePuentes/DatosPuentes';
import DatosPuentesEstado from '@/components/AltaDePuentes/DatosPuentesEstado';
 import Catalogos from '@/components/catIC/DatosPuentes'; */
/* import DatosBeneficiario from '@/components/AltaDePuentes/DatosBeneficiario';
import DatosFinancieros from '@/components/AltaDePuentes/DatosFinancieros';
import AsignarResidente from '@/components/AsignarResidente'; */
import { mapMutations } from 'vuex'
/*import convenio from '@/components/convenio';
import Convenio from '../components/convenio.vue';*/
export default {
  name: 'catIC',
  components: {cattpred,
               catautopista,
               catpuentes,
               catredoperada,
               catcarriles,
               catclaseveh,
               cattipoveh,
               catrangos,
               catejestroncales,
               cattipocarreteras,
               catprogramas,
               catdependencias
              },
  data () {
    return {
        camino_id:0,
        cEstado: '',
        msgError: null,
        breadcrumb: ['Puente '+ this.$route.params.obraId],
    }    
  },
  methods: {
    setCEdo (e) {
      const obj = JSON.parse(JSON.stringify(e))
      this.cEstado = obj.datos
    },
    showError(e){
      console.log('show-error')
      this.msgError = e;
      console.log(e)
      //setTimeout(() => this.msgError = false, 10000);
    },
    ...mapMutations(['setBreadcrumb']),    
  },
  computed: {
      getCaminoId(){
          return this.camino_id
      }
  },
  created() {
  },
  beforeMount: function () {    
    if(this.$route.params.obraId){
      this.setBreadcrumb(this.breadcrumb)
    }
  }, 
}
</script>



