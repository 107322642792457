<template>
<div>
<table ref="catclaseveh" id="catclaseveh" class="tableContenido" width="100%" border="0">
    <tbody>
        <tr>
            <td>
                <h2>Clase de Vehiculos</h2>
                <hr class="red">
            </td>
        </tr> 

         <tr>
             <td>
                <div class="col-md-12 form-group">
                    <div class="col-md-6">
                        <label>Clase de Vehiculos :</label>
                        <input id="clasveh"  v-model="clasveh" placeholder="Clase de Vehiculos" maxlength="20" class="form-control">
                        
                    </div>
                    
                </div>    
                
                <div class="col-md-12 form-group">
                <div class="col-md-6">
                        <label> </label><br>
                        <label class="checkbox-inline">
                              <input type="checkbox" id="activo" value="true"  name="activo" v-model="activo" > 
                            Activo
                        </label>
                    </div>
                </div>   
                <div class="col-md-12 form-group">
                    <div class="col-md-6">
                        <label></label>
                    </div>
                    <div class="col-sm-6">
                        <button type="button" v-on:click="Datostpred" class="btn btn-default pull-right vertical-buffer" data-toggle="modal">Guardar Datos</button>
                    </div> 
                </div>
             </td>   
         </tr>   
         
         <tr>
             <td>
              <div class="row">
                <div class="col-md-12">
                    <h5 class="small-top-buffer small-bottom-buffer">Clase de Vehiculos</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 table-responsive">  
                    <ejs-grid   ref="grid"
                        :dataSource="lista" 
                        :gridLines='lines' 
                        :allowPaging='true' 
                        :allowSorting='true'
                        :pageSettings='pageSettings'
                        :allowFiltering='true'
                        :allowTextWrap='true'
                        :rowSelected='rowSelected'
                    >
                        <e-columns>
                            <e-column field='id' headerText='id' :visible='flag'></e-column>
                            <e-column field='clasveh'   headerText='Clase de Vehiculos' ></e-column>
                            <e-column field='clasvehactivo' headerText='Activo' ></e-column>                 
                        </e-columns>
                    </ejs-grid> 
                </div>
              </div>   
             </td>   
         </tr>


    </tbody>
</table>
</div>
</template>

<script>
import Vue from "vue";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { ComboBoxPlugin, MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { getEdos, getMunicipios, getLocalidades } from '@/api/alta-camino'
import { getupdate } from '@/api/alta-camino';
import { required } from 'vuelidate/lib/validators'

Vue.use(NumericTextBoxPlugin);
Vue.use(ComboBoxPlugin);
Vue.use(MultiSelectPlugin);

const API = process.env.VUE_APP_SCT_SVC_BACK_BASE_URL;

export default {
    name: 'DatosGeograficosPuentes',   
    props:{
        camino_id:{
            type:Number
        }
    }, 
    data: function() {
        return {  
            flag: false,   
            lista:lista,
            valueEstado: 0,
            ip_poblacion_total_localidades: 0,
            ipoblacion_municipio: 0,
            ilocalidades_municipio: 0,
            ubicacion: '',
            region: '',
            icve_estado_inegi: null,
            estadosHabilitado: false,
            estadosData: new DataManager([]),
            estadosFields: { text: 'nom_agee', value: 'cve_agee' },
            icve_municipio: null,
            municipiosHabilitado: false,
            municipiosData: new DataManager([]),
            municipiosFields: { text: 'nom_agem', value: 'cve_agem', custom: 'cve_agee' },
            localidades: [],
            localidadesHabilitado: false,
            localidadesData: new DataManager([]),
            localidadesFields: { text: 'nom_loc', value: 'cve_loc' },
            localidadesTabla: [],
            iTotalPoblacionIndigena: null,
            poblacion: 0,
            marginacion: null,
            poblacion_indigena: null,
            isoEdo:'',
            editmode: false,

            
            DatosGeograficosPuentes:{
                
                    abreviaturaEdo: '',
                    iso: '',
                    localidades: '',
                    icveestados: '',
                    region: '',
                    ubicacion: '',
                    poblacion_indigena: '',
                    totpoblacion: '',
                    icve_estado_inegi: '',
                    icve_municipio: '',
                    ip_poblacion_total_localidades: '',
                    ipoblacion_municipio: '',
                    ilocalidades_municipio: '',
                    marginacion: '', 
                    cve_agee: '',
                    /*1*/
                
                }
        };
    },
     validations: {
        icve_estado_inegi: {
            required,

        }, 
        icve_municipio: {
           required,
        },
    },
    
 
 

    methods: {
        //Envia datos Region
        enviardatos_r(){
            this.$emit("set-icveEdo", {
            datos:this.DatosGeograficosPuentes
        }); 
        },
        async CargaDatos(clave){
              const response = await getupdate(clave)
              this.$emit('update:camino_id', response.id)
              
              this.$refs.refEstado.ej2Instances.value = response.cve_agee
              const {results} = await getMunicipios(response.cve_agee)
              this.municipiosData = new DataManager(results);
              this.$refs.refMunicipio.ej2Instances.value = response.icve_municipio
              
              const res = await getLocalidades(response.cve_agee, 
                                               response.icve_municipio)        
                this.ilocalidades_municipio = res.length;
                this.ip_poblacion_total_localidades = res.reduce((x, y) => {
                    return x + y.pob;
                    },0)
                this.icve_estado_inegi = response.cve_agee
                this.localidadesHabilitado = false;            
                this.localidadesData = new DataManager(res);
                this.icve_municipio = response.icve_municipio
                this.$refs.localidades.ej2Instances.value = response.localidades
                this.localidades = this.$refs.localidades.ej2Instances.value
                const localidadesData = this.localidadesData.executeLocal(new Query());
                this.localidadesTabla = localidadesData
                .filter(a => this.localidades.includes(a.cve_loc));
              this.region = response.datos_geograficos.region
              this.ubicacion = response.datos_geograficos.ubicacion
              this.poblacion_indigena = response.datos_geograficos.poblacion_indigena
              this.marginacion = response.datos_geograficos.marginacion
              this.totpoblacion = response.datos_geograficos.totpoblacion
              //this.icve_estado_inegi = Number.parseInt(response.datos_geograficos[0].icve_estado_inegi) 
              //this.icve_municipio = response.datos_geograficos[0].icve_municipio
              //this.ip_poblacion_total_localidades = response.datos_geograficos[0].ip_poblacion_total_localidades
              this.ipoblacion_municipio = response.datos_geograficos.ipoblacion_municipio
              //this.ilocalidades_municipio = response.datos_geograficos[0].ilocalidades_municipio
              this.setEdoIso()
              this.recalcularPoblacionTotal()   
              this.estadosHabilitado = false                
        },
        //Envia datos Ubicacion
        enviardatos_u(){
            this.$emit("set-icveEdo", {
            datos:this.DatosGeograficosPuentes
            
        }); 
        },        
        //Funcion fregion
        fregion(){
            this.DatosGeograficosPuentes.region = this.region
            this.enviardatos_r()
        },
         //Funcion funibacion
        fubicacion(){
            this.DatosGeograficosPuentes.ubicacion = this.ubicacion
            this.enviardatos_u()
        },



        //NEW ORDER
        //estados
        async siguiente(){   
            
            this.$emit("show-error", false); 
            this.$v.$touch()
            if (this.$v.$invalid) {
               this.submitStatus = "Error";
            }
            },
        async initData() {
            try{
                const res = await getEdos()
                const results = res.results;
                this.estadosData = new DataManager(results);
                this.estadosHabilitado = true;
                this.icve_estado_inegi = null;       
            }catch(error) {             
                console.log('error al obtener estados')
                console.log(error);
                this.$emit("show-error", error);
            }
        },
        //municipios
        async obtenerMunicipios(){            
            this.$emit("show-error", false);
            this.icve_municipio = null;
            this.municipiosData = new DataManager([]);
            this.municipiosHabilitado = true;    
            this.clearLocalidades();            
            try{
                const {results} = await getMunicipios(this.icve_estado_inegi)
                this.municipiosData = new DataManager(results);
                this.municipiosHabilitado = true;            
            }catch(err){
                console.log('error al obtener municipios')
                console.log(err)
                this.$emit("show-error", err);
            }
        },
        //localidades
        async obtenerLocalidades(){
            try{                
                this.$emit("show-error", false);
                this.clearLocalidades();
                const res = await getLocalidades(this.icve_estado_inegi, 
                                                 this.icve_municipio)        
                this.ilocalidades_municipio = res.length;
                this.ip_poblacion_total_localidades = res.reduce((x, y) => {
                    return x + y.pob;
                    },0)
                
                this.localidadesHabilitado = true;            
                this.localidadesData = new DataManager(res);
                this.setEdoIso()
                 this.DatosGeograficosPuentes.localidades = this.localidades
                 this.DatosGeograficosPuentes.icveestados = this.icveestados
                 this.DatosGeograficosPuentes.region      = this.region
                 this.DatosGeograficosPuentes.ubicacion   = this.ubicacion
                 this.DatosGeograficosPuentes.poblacion_indigena = this.poblacion_indigena
                 this.DatosGeograficosPuentes.totpoblacion = this.ipoblacion_municipio
                 this.DatosGeograficosPuentes.icve_estado_inegi = this.icve_estado_inegi
                 this.DatosGeograficosPuentes.icve_municipio = this.icve_municipio
                 this.DatosGeograficosPuentes.ip_poblacion_total_localidades = this.ip_poblacion_total_localidades
                 this.DatosGeograficosPuentes.ipoblacion_municipio = this.ipoblacion_municipio
                 this.DatosGeograficosPuentes.ilocalidades_municipio = this.ilocalidades_municipio
                 this.DatosGeograficosPuentes.marginacion = this.marginacion
                  this.$emit("set-icveEdo", {
                     datos:this.DatosGeograficosPuentes
                 });
            }catch(error){
                console.log('error al obtener localidades')
                console.log(error);
                this.$emit("show-error", error);
            }
        },

        recalcularPoblacionTotal() {      
                        
            if (this.localidades.length > 0) {   
                const localidadesData = this.localidadesData.executeLocal(new Query());
                this.localidadesTabla = localidadesData
                .filter(a => this.localidades.includes(a.cve_loc));

                this.ipoblacion_municipio = localidadesData
                .filter(a => this.localidades.includes(a.cve_loc))
                .map(a => a.pob)
                .reduce((a, b) => (a + b), 0);
                 if(!isNaN(this.localidades)){
                     if(!Array.prototype.isPrototypeOf(this.localidades)){
                         this.localidades = [this.localidades]
                     }                     
                 }
                 this.DatosGeograficosPuentes.localidades = this.localidades
                 this.DatosGeograficosPuentes.icveestados = this.icveestados
                 this.DatosGeograficosPuentes.region      = this.region
                 this.DatosGeograficosPuentes.ubicacion   = this.ubicacion
                 this.DatosGeograficosPuentes.poblacion_indigena = this.poblacion_indigena
                 this.DatosGeograficosPuentes.totpoblacion = this.ipoblacion_municipio
                 this.DatosGeograficosPuentes.icve_estado_inegi = this.icve_estado_inegi
                 this.DatosGeograficosPuentes.icve_municipio = this.icve_municipio
                 this.DatosGeograficosPuentes.ip_poblacion_total_localidades = this.ip_poblacion_total_localidades
                 this.DatosGeograficosPuentes.ipoblacion_municipio = this.ipoblacion_municipio
                 this.DatosGeograficosPuentes.ilocalidades_municipio = this.ilocalidades_municipio
                 this.DatosGeograficosPuentes.marginacion = this.marginacion
                
                 /*2*/
                 this.$emit("set-icveEdo", {
                     datos:this.DatosGeograficosPuentes
                 }); 
            
            } else {
                this.localidadesTabla = [];
                this.ipoblacion_municipio = 0;
            }
        },             
        updateLocalidades(e){
            this.localidades = this.$refs.localidades.ej2Instances.value
            this.recalcularPoblacionTotal()

        },

        clearLocalidades(){
            this.localidadesData = null;
            this.localidadesHabilitado = false;
            this.localidades = [];
            this.localidadesTabla = [];
        },

        formatNum(num){
            return new Intl.NumberFormat().format(num);
        },

        setEdoIso(){
            
            const edoSelect = this.estadosData.executeLocal(new Query())
                                .filter(a => a.cve_agee == this.icve_estado_inegi)                        
            console.table(edoSelect)
            this.DatosGeograficosPuentes.iso = edoSelect[0].iso
            this.DatosGeograficosPuentes.cve_agee = this.icve_estado_inegi
 /*           this.$emit("set-icveEdo", {
                edo:{
                    abreviaturaEdo:this.icve_estado_inegi,
                    iso:edoSelect[0].iso
                }
                }); 
 */         
            const munSelect = this.municipiosData.executeLocal(new Query())
                                .filter(a => a.cve_agem == this.icve_municipio) 
            this.marginacion = munSelect[0].grado_marginacion.descripcion
            this.poblacion_indigena = munSelect[0].poblacion_indigena.descripcion
            this.iTotalPoblacionIndigena = munSelect[0].total_poblacion_indigena
            this.iTotalPoblacionIndigena = this.formatNum(this.iTotalPoblacionIndigena)
            var str = JSON.stringify(munSelect, null, 2); // spacing level = 2                      
        },
            actualizadatos(){
                this.DatosGeograficosPuentes.localidades = this.localidades
                 this.DatosGeograficosPuentes.icveestados = this.icveestados
                 this.DatosGeograficosPuentes.region      = this.region
                 this.DatosGeograficosPuentes.ubicacion   = this.ubicacion
                 this.DatosGeograficosPuentes.poblacion_indigena = this.poblacion_indigena
                 this.DatosGeograficosPuentes.totpoblacion = this.ipoblacion_municipio
                 this.DatosGeograficosPuentes.icve_estado_inegi = this.icve_estado_inegi
                 this.DatosGeograficosPuentes.icve_municipio = this.icve_municipio
                 this.DatosGeograficosPuentes.ip_poblacion_total_localidades = this.ip_poblacion_total_localidades
                 this.DatosGeograficosPuentes.ipoblacion_municipio = this.ipoblacion_municipio
                 this.DatosGeograficosPuentes.ilocalidades_municipio = this.ilocalidades_municipio
                 this.DatosGeograficosPuentes.marginacion = this.marginacion
                 /*2*/
                 this.$emit("set-icveEdo", {
                     datos:this.DatosGeograficosPuentes
                 }); 
    },
    },    
    mounted() {
        this.$nextTick(() => {
            })
    },
    async created() {    
        await this.initData()
        if(this.$route.params.obraId){
            this.estadosHabilitado = false
            this.CargaDatos(this.$route.params.obraId)
            this.editmode = true
        }               
    },
    computed:{
        getEditmode(){
            return this.estadosHabilitado
        }
    }
}

const lista =
[
    {
    id:1,
tpred:'Cuota ',
activo:'SI'
    } ,
    {
    id:2,
tpred:'Libre ',
activo:'SI'
    },
    {
    id:3,
tpred:'Alimentadora',
activo:'SI'
    },
    {
    id:4,
tpred:'Rural',
activo:'SI'
    },
    {
    id:5,
tpred:'Brechas',
activo:'SI'
    }
]


</script> 

<style>
@import "./../../../node_modules/@syncfusion/ej2-bootstrap-theme/styles/bootstrap.css";
/*
@import "ej2/base/bootstrap.scss";
*/
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.e-control.e-numerictextbox.e-lib.e-input{
    color: black !important;
    font-size: 18px;
    border: 0px ;
}

</style>