<template>
<Modal 
    v-model="isShowModal" 
    title="Importación de Datos de Longitud Carretera" 
    :modal-class="`modal1 ${modal_xl}`"
    wrapper-class="modal-wrapper"
    :enable-close="enableClose"
    @before-open="beforeOpen"
>
    <div class="modal-body">
        <div class="col-sm-6">
                        <p>&nbsp;</p>
                        <label class="control-label" for="financiero">Seleccione el archivo a importar</label>
                        <input type="file" name="archivo" id="archivo" v-on:change="vistaArchivo"> 
        </div>
        <div class="col-sm-6">
                        <p>&nbsp;</p>
                        <label class="control-label" for="financiero">Informacion año: <strong> {{anio}}</strong></label>
                        <br>
                        <select v-model="hoja" @change="vistaArchivo">
                            <option value="Resumen Nal" selected>Resumen Nacional</option>
                            <option value="AGS">Aguascalientes</option>
                            <option value="BC">Baja California</option>
                            <option value="BCS">Baja California Sur</option>
                            <option value="CAM">Campeche</option>
                            <option value="COAH">Coahuila</option>
                            <option value="COL">Colima</option>
                            <option value="CHIS">Chiapas</option>
                            <option value="CHIH">Chihuahua</option>
                            <option value="CDMX">Ciudad de México</option>
                            <option value="DGO">Durango</option>
                            <option value="GTO">Guanajuato</option>
                            <option value="GRO">Guerrero</option>
                            <option value="HGO">Hidalgo</option>
                            <option value="JAL">Jalisco</option>
                            <option value="MEX">Estado de México</option>
                            <option value="MICH">Michoacán</option>
                            <option value="MOR">Morelia</option>
                            <option value="NAY">Nayarit</option>
                            <option value="NL">Nuevo León</option>
                            <option value="OAX">Oaxaca</option>
                            <option value="PUE">Puebla</option>
                            <option value="QRO">Querétaro</option>
                            <option value="QROO">Quintana Roo</option>
                            <option value="SLP">San Luis Potosí</option>
                            <option value="SIN">Sinaloa</option>
                            <option value="SON">Sonora</option>
                            <option value="TAB">Tabasco</option>
                            <option value="TAM">Tamaulipas</option>
                            <option value="TLAX">Tlaxcala</option>
                            <option value="VER">Veracruz</option>
                            <option value="YUC">Yucatán</option>
                            <option value="ZAC">Zacatecas</option>
                            
                        </select>
                       
        </div>

        <div class="row" id="dvProgreso" style="display:none">
            <div class="col-md-12"> <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p> </div> 
            <div class="col-md-12">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" id="barraProg"></div>
                </div> 
            </div>           
        </div>

        <div class="row" id="dvVistaPrevia">
            <div class="col-md-12">

               <ejs-grid   ref="grid"
                            :dataSource="datos" 
                            :gridLines='true' 
                            :allowPaging='true' 
                            :allowSorting='true'
                            :pageSettings='pageSettings'
                            :allowFiltering='true'
                            :allowTextWrap='true'
                            >
                    <e-columns>
                        <!--<e-column field='anio' headerText='Año' width='100' textAlign="Center" minWidth='10'></e-column>
                        <e-column field='cuota' headerText='Cuota' :columns='cuotaColumns' textAlign="Center"></e-column>
                        <e-column headerText='Libre' :columns='LibreColumns' textAlign="Center"></e-column>
                        <e-column headerText='Alimentadoras' :columns='AlimColumns' textAlign="Center"></e-column>-->
                        <e-column field='clasif'     headerText='Clasificación' width=200></e-column>
                        <e-column field='cuatrom'    headerText='Más de 4 carriles' ></e-column>
                        <e-column field='cuatro'   headerText='4 carriles' ></e-column> 
                        <e-column field='dos'    headerText='2 carriles' ></e-column>
                        <e-column field='revestidas'    headerText='Revestidas' ></e-column>
                        <e-column field='terracerias'   headerText='Terracerias' ></e-column> 
                        <e-column field='total'    headerText='TOTAL' ></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-right">
            <hr />
            <button @click="animaPB();" type="button" class="btn btn-primary" data-dismiss="modal">Guardar</button>
            <button @click="closeModal" type="button" class="btn btn-primary" data-dismiss="modal" id="btnCerrarMod">Cerrar</button>
            </div>
        </div>         
    </div> 
</Modal>
</template>

<script>
import Vue from "vue";
import { GridPlugin, Page } from "@syncfusion/ej2-vue-grids";
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import {Grid  } from 'gridjs-vue'
import { h } from "gridjs";
import bodyScroll from 'body-scroll-freezer'
import { getAllExtraordinarios }  from '@/api/extraordinarios'
import { vistaArchivoCarreteras, guardaArchivoCarreteras } from '@/api/alta-camino'
import { Message, Loading} from 'element-ui'


Vue.use(GridPlugin)

let valp = 0, tm = null;

export default {
    name:'modalExtraordinarios',
    components: {
        'Modal': VueModal,
        Grid
    },
    props: {
        showModal:{
            type: Boolean,
            default: false
        },
        loadedExtraordinarios: {
            type:Array,
            default:[]
        }
    },
    data() {
        return {
             pageSettings:{ pageCount:8, pageSize: 6  },
            anio:'',
            hoja:'Resumen Nal',
            chosen: '',
            showModal:false,
            extraordinarios:[],
            datos: [],
            width:'100%',
            enableClose: false,
            modal_xl:'modal-xl',
            language: {
                'search': {
                    'placeholder': 'Buscar...'
                },
                'pagination': {
                    'previous': '⬅️',
                    'next': '➡️',
                    'showing': 'Mostrando de',
                    'results': () => 'Resultados',
                    of: 'de',
                    to: 'a',                
                },
                loading: 'Cargando...',
                noRecordsFound: 'No hay partidas para agregar',
                error: 'Ocurrio un error al obtener los datos',
            },
            pagination: {
                limit: 20
            },
            //cols: [
            //    { 
            //        id:'id',
            //        name: 'ID',
            //        hidden: true
            //    },
            //    {
            //        id:'anio',
            //        name:'Año',
            //    }, 
            //    {
            //        data: (row) => row.unidad_medida.id,
            //        //id:'unidad_medida.codigo',
            //        name:'id_unidad',
            //        hidden: true
            //    },                   
            //    {
            //        data: (row) => row.unidad_medida.codigo,
            //        //id:'unidad_medida.codigo',
            //        name:'Unidad',
            //    },    
            //    {
            //        id:'importe',
            //        name:'Precio Unitario',
            //        formatter: (_, row) => `$${(Number(row.cells[4].data)).toLocaleString(
            //            'en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}
            //                )}`
            //    },                                               
            //    {
            //        name: 'Acciones',
            //        formatter: (cell, row) => {
            //            return h('button', {
            //                className: 'btn btn-primary btn-sm',
            //                onClick: ()  => this.addExtraordinario(row)
            //            }, 'Agregar');
            //            }
            //    },                                
            //],
            //rows: [
            //],
            //className: {
            //    td: 'my-td-class',
            //    table: '' 
            //},                                 
        }
    },
    methods:{
        closeModal(){
            valp = 0;
            $('#barraProg').css('width',valp +'%')
            $('#barraProg').attr('aria-valuenow', valp)
            tm = null;
            this.anio = ''
            this.hoja = 'Resumen Nal'
            this.datos = []
            this.$refs.grid.ej2Instances.dataSource = this.datos
            bodyScroll.unfreeze()
            this.$emit('update:showModal', false)
        },
        //modal methods
        beforeOpen() {
            bodyScroll.freeze()
            //this.populate()
            console.log('beforeOpen')
        },  
        async vistaArchivo(){
             //let loadingInstance = Loading.service({ fullscreen: false, lock: true });

            var formData = new FormData();
            var file = document.querySelector('#archivo');
            formData.append("archivo", file.files[0]);
            formData.append("hoja",this.hoja)

            try
            {
                let rs = await vistaArchivoCarreteras(formData)
                this.anio = rs.anio
                this.datos = rs.data
                this.$refs.grid.ej2Instances.dataSource = this.datos
            }
            catch(error)
            {
                Message({
                            message: 'Error al LEER el ARCHIVO',
                            type: 'error', 
                            duration: 3 * 1000
                        })
            }

            //loadingInstance.close()
        },

        
        //async guardaArchivo(){
//
        //    var formData = new FormData();
        //    var file = document.querySelector('#archivo');
        //    formData.append("archivo", file.files[0]);
//
        //    try
        //    {
        //        let rs = await guardaArchivoCarreteras(formData)
        //        //this.datos = rs.data
        //        //this.$refs.grid.ej2Instances.dataSource = this.datos
        //        Message({
        //                    message: rs.Message,
        //                    type: 'success', 
        //                    duration: 3 * 1000
        //                })
        //    }
        //    catch(error)
        //    {
        //        Message({
        //                    message: 'Repita el proceso de importación de los datos.',
        //                    type: 'error', 
        //                    duration: 3 * 1000
        //                })
        //    }
        //    
        //},
        animaPB(){
            $('#dvVistaPrevia').hide()
            $('#dvProgreso').show()
            try{
                var tm = setInterval(anima,100);

                function anima()
                {
                    $('#barraProg').css('width',valp +'%')
                    $('#barraProg').attr('aria-valuenow', valp)
                    valp += 10

                    if (valp === 100)
                    {
                        guardaArchivo()
                    }
                    else if ( valp > 150)
                    {
                        try{
                        $('#dvVistaPrevia').show()
                        $('#dvProgreso').hide()
                        clearInterval(tm)
                        
                        //closeModal()
                        $('#btnCerrarMod').click()
                        //alert(1)
                        }
                        catch(error)
                        {
                            alert(error)
                        }
                    }

                }
                async function guardaArchivo(){
                    var formData = new FormData();
                    var file = document.querySelector('#archivo');
                    formData.append("archivo", file.files[0]);

                    try
                    {
                        let rs = await guardaArchivoCarreteras(formData)
                        //this.datos = rs.data
                        //this.$refs.grid.ej2Instances.dataSource = this.datos
                        Message({
                                    message: rs.Message,
                                    type: 'success', 
                                    duration: 3 * 1000
                                })
                    }
                    catch(error)
                    {
                        Message({
                                    message: 'Repita el proceso de importación de los datos.',
                                    type: 'error', 
                                    duration: 3 * 1000
                                })
                    }  
                }                

            }
            catch(error)
            {
                alert(error)
            }
        },
        beforeClose() {
            bodyScroll.unfreeze()
        },

        //addExtraordinario(data){
        //    console.log('data')
        //    console.log(data.cells[0].data)
        //    const concept = {
        //        id:data.cells[0].data,
        //        concepto:data.cells[1].data,
        //        unidad:data.cells[2].data,
        //        cantidad_total:0.000001,                
        //        precio_unitario:data.cells[4].data,
        //        importe_total:0.0001,
        //        pp:0.00
//
        //    }
        //    this.beforeClose()
        //    this.$emit('addExtraordinario', concept)
        //    this.$emit('update:showModal', false)
        //},
        //async populate(){
        //    let ids = []
        //    this.loadedExtraordinarios.map( i => ids.push(i.id))
        //    console.log('ids')
        //    console.log(ids)
        //    let { results } = await getAllExtraordinarios()
        //    console.log('data')
        //    console.log(results)
        //    results = results.filter( el => !ids.includes(el.id) )
        //    console.log(results)
        //    this.rows = results
        //}
    },    
    computed:{
        isShowModal() {
             return  this.showModal         
        }
    },
    mounted() {
        bodyScroll.init()               
    },   
    
    provide: {
    grid: [Page]
  }
}
</script>

<style>
.modal-wrapper {
  display: flex;
  align-items: center;
}
.modal-wrapper .vm {
  top: auto;
}

.modal1 {
  min-width: 300px;
}
@media (min-width: 480px) {
  .modal1.modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal1.modal-lg,
  .modal1.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal1.modal-xl {
    max-width: 1140px;
  }
}
.modal1-footer {
  padding: 15px 0px 0px 0px;
  border-top: 1px solid #e5e5e5;
  margin-left: -14px;
  margin-right: -14px;
}
.gridjs-td {
    font-size: 14px;
}

.vm-titlebar {
    background-color: #393C3E;
    color: #fff;
    text-align: center;    
}

.vm-title {
    font-size: 20px !important;
}

td.gridjs-td {background-color:  transparent !important;}
tr.gridjs-tr:hover {background-color:#f5f5f5;}
</style>