<template>
<div class="row">
  <div class="col-md-12">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active" id="tabPersonal">
          <a href="#catgprod" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
            Grupo de productos
          </a>
        </li>
        <li role="presentation"  id="tabcataduana">
          <a href="#cataduana" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Aduana
          </a>
        </li>
        <li role="presentation"  id="tabcatproduc">
          <a href="#catproduc" aria-controls="profile" role="tab" data-toggle="tab" id="input-1" aria-expanded="true">
           Productos
          </a>
        </li>
    </ul>
    <div class="tab-content">
        <div role="tabpanel" class="tab-pane active" id="catgprod">
          <catgprod  @set-icveEdo="setCEdo" @show-error="showError" 
            :camino_id.sync="camino_id"/>
        </div>
        <div role="tabpanel" class="tab-pane" id="cataduana">
          <cataduana  @show-error="showError"> </cataduana>
        </div>
        <div role="tabpanel" class="tab-pane" id="catproduc">
          <catproduc  @show-error="showError"> </catproduc>
        </div>
               
    </div>
  <div>
    <div v-if="msgError" class="alert alert-danger">{{ msgError }}</div>
  </div>    
  </div>
</div>
</template>

<script>
import catgprod from '@/components/catTF/catgprod';
import cataduana from '@/components/catTF/cataduana';
import catproduc from '@/components/catTF/catproduc';

import { mapMutations } from 'vuex'

export default {
  name: 'catTF',
  components: {catgprod,
               cataduana,
               catproduc
              },
  data () {
    return {
        camino_id:0,
        cEstado: '',
        msgError: null,
        breadcrumb: ['Puente '+ this.$route.params.obraId],
    }    
  },
  methods: {
    setCEdo (e) {
      const obj = JSON.parse(JSON.stringify(e))
      this.cEstado = obj.datos
    },
    showError(e){
      console.log('show-error')
      this.msgError = e;
      console.log(e)
      //setTimeout(() => this.msgError = false, 10000);
    },
    ...mapMutations(['setBreadcrumb']),    
  },
  computed: {
      getCaminoId(){
          return this.camino_id
      }
  },
  created() {
  },
  beforeMount: function () {    
    if(this.$route.params.obraId){
      this.setBreadcrumb(this.breadcrumb)
    }
  }, 
}
</script>



