import { render, staticRenderFns } from "./catindicadores.vue?vue&type=template&id=2341daea"
import script from "./catindicadores.vue?vue&type=script&lang=js"
export * from "./catindicadores.vue?vue&type=script&lang=js"
import style0 from "./catindicadores.vue?vue&type=style&index=0&id=2341daea&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports