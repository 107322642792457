import request from '@/utils/request-login'

export function login(data) {

  return request ({
    url: '/login',
    method: 'post',
    data: {
      email: data.username,
      password: data.password
    }
  })
}

export function refreshToken(data){
  return request ({
    url: '/refresh',
    method: 'post',
    data
  })
}

export function veryfyToken(data){
  return request ({
    url: '/verify',
    method: 'post',
    data
  })
}

export function logout(d) {
  return request({
    url: '/logout',
    method: 'post',
    data: {
      refresh: d
    }
  })
}
