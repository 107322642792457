import request from '@/utils/request'

export function login(data) {
  return (
    {
      refresh: 'refresh_token_dummy',
      access: 'access_token_dummy',
      perfil: 'perfil_dummy',
      name: 'nombre_de_usuario_dummy'
    }
  )
}

export function refreshToken(data){
  return request ({
    url: '/token/refresh',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}
