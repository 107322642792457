<template>
<div id="app">
    <div class="row">  
        <div class="col-md-12 mx-auto">
            <div class="row">
                    <h2>Tránsito de Vehículos en las Casetas de Cobro de Caminos y Puentes Federales de Ingresos y Servicios </h2>
            </div>
        </div>
        
        <center>
        
        <table >
            <tr>
                <td width="15px"></td>
                <td width="180px">
                    <button 
                                id="btn-add-partida"
                                class="btn btn-danger btn-sm" 
                                type="button" 
                                @click="showModal = true"    
                            >
                        <span class="glyphicon "></span> Importar archivo
                    </button>                    
                </td>
                <td width="50px">&nbsp;</td>
                <td width="120px">Año a consultar</td>
                <td width="80px">
                <vue-infinite-autocomplete 
                    :data-source=currentOptions
                    :value=currentVAlue
                    v-on:select="handleOnSelect"
                    >
                </vue-infinite-autocomplete>                     
                </td>
                <td width="50px">&nbsp;</td>
                <td width="200px">
                    <button class="btn btn-default btn-sm" type="button" id="btnBuscarRedCarr" @click="consultaRedCarr">
                    <span ></span>Buscar</button>                    
                </td>
                
                <td width="50px">&nbsp;</td>
                <td>&nbsp;
                    <!-- <button class="btn btn-default btn-sm" type="button" id="capcaminos" data-toggle="modal" >
                    <span ></span>Capturar Caminos</button> -->                    
                </td>
                
            </tr>
        </table>
        </center>
        <center>
        <table >
            <tr>
              <td width="15px" height="20px"></td>
                <td width="180px"></td>
                <td width="50px">&nbsp;</td>
                <td width="120px"></td>
                <td width="80px"></td>
                <td width="50px">&nbsp;</td>
                <td width="200px"></td>
                <td width="50px">&nbsp;</td>
                <td></td>
            </tr>
        </table>
        </center>
        <center>
        <table >
            <tr>
              <td width="15px"></td>
                <td width="180px"></td>
                <td width="50px">&nbsp;</td>
                <td width="120px"></td>
                <td width="80px"></td>
                <td width="50px">&nbsp;</td>
                <td width="200px"></td>
                <td width="50px">&nbsp;</td>
                <td></td>
            </tr>
        </table>
        </center>
        <center>
        <table >
            <tr>
              <td width="15px"></td>
                <td width="180px"></td>
                <td width="50px">&nbsp;</td>
                <td width="1200px"></td>
                <td width="80px"></td>
                <td width="50px">&nbsp;</td>
                <td width="200px"></td>
                <td width="50px">&nbsp;</td>
                <td></td>
            </tr>
        </table>
        </center>
        <center>
        <table >
            <tr>
                <td width="15px"></td>
                <td width="180px">
                    <button class="btn btn-default btn-sm" type="button" id="exportaXls" data-toggle="modal" data-target="#exampleModalCenter"><!--@click="exportaDatos" -->
                    <span ></span>Exporta a Excel</button>                    
                </td>
                <td width="50px">&nbsp;</td>
                <td width="120px"></td>
                <td width="80px"></td>
                <td width="50px">&nbsp;</td>
                <td width="200px"></td>
                <td width="50px">&nbsp;</td>
                <td>&nbsp;
                    <!-- <button class="btn btn-default btn-sm" type="button" id="cappuentes" data-toggle="modal" >
                    <span ></span>Capturar Puentes</button>  -->                   
                </td>
            </tr>
        </table>
        </center>
        <div class="col-sm-12">
            <p>&nbsp;</p>
        </div> 
    </div>
    <center>
    <h2>Tránsito de Vehículos en Caminos </h2>
    </center>
    <ejs-grid ref="grid" 
    :dataSource="datacam" 
    :allowResizing='true' 
    :editSettings='editSettings' 
    :detailDataBound='onDetailDataBound' >
        <e-columns>      
            <e-column headerText='' :visible='false'                   textAlign="Center"></e-column>
            <e-column field='anio' headerText='Año'                    textAlign="Center"></e-column>
            <e-column headerText='Propia'                              textAlign="Center"></e-column>
            <e-column headerText='Contratada'                          textAlign="Center"></e-column>
            <e-column headerText='FONEM-'                               textAlign="Center"></e-column>
            <e-column headerText='Total'                               textAlign="Center"></e-column>
            <e-column headerText='Exportar' :template='exportTemplate' textAlign='Center'></e-column>
        </e-columns>
    </ejs-grid>
    <center>
    <h2>Tránsito de Vehículos en Puentes</h2>
    </center>
    <!-- <ejs-treegrid ref="grid"
      :dataSource="datapte" 
      :allowResizing='true' 
      :editSettings='editSettings' 
      :detailDataBound='onDetailDataBound'
      :treeColumnIndex="1" 
    >
    
        <e-columns>    
            <e-column headerText='' :visible='false' textAlign="Center"></e-column>  
            <e-column field='anio' headerText='Año' textAlign="Center"></e-column>
            <e-column headerText='Propia'  textAlign="Center"></e-column>
            <e-column headerText='Contratada'   textAlign="Center"></e-column>
            <e-column headerText='FONEM'  textAlign="Center"></e-column>
            <e-column headerText='Total' textAlign="Center"></e-column>
            <e-column headerText='Exportar' :template='exportTemplate' textAlign='Center'></e-column>
        </e-columns>
      
    </ejs-treegrid> -->
     <ejs-grid ref="grid" 
    :dataSource="datapte" 
    :allowResizing='true' 
    :editSettings='editSettings'  
    :detailDataBound='onDetailDataBound' > 
        <e-columns>    
            <e-column headerText='' :visible='false' textAlign="Center"></e-column>  
            <e-column field='anio' headerText='Año' textAlign="Center"></e-column>
            <e-column headerText='Propia'  textAlign="Center"></e-column>
            <e-column headerText='Contratada'   textAlign="Center"></e-column>
            <e-column headerText='FONEM'  textAlign="Center"></e-column>
            <e-column headerText='Total' textAlign="Center"></e-column>
            <e-column headerText='Exportar' :template='exportTemplate' textAlign='Center'></e-column>
        </e-columns>
    </ejs-grid> 

    <modalExtraordinarios 
        :showModal.sync="showModal"
        :loadedExtraordinarios="extraordinarios"
    >
    </modalExtraordinarios> 

    <modalExtraordinarios 
        :showModal.sync="showModal"
        :loadedExtraordinarios="extraordinarios"
    >
    </modalExtraordinarios> 

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <table class="table table-hover" style="font-size:small">
              <tr style="font-weight:bold" align="center">
                  <td >Clasificación</td>
                  <td>Más de 4 carriles</td>
                  <td>4 carriles</td>
                  <td>2 carriles</td>
                  <td>Revestidas</td>
                  <td>Terracerias</td
                  ><td>Total</td>
              </tr>
              <tr style="background-color:#ccc">
                  <td>Red Federal de Carreteras</td>
                  <td><input type="number" style="width:100px" id="r13c1"></td>
                  <td><input type="number" style="width:100px" id="r13c2"></td>
                  <td><input type="number" style="width:100px" id="r13c3"></td>
                  <td><input type="number" style="width:100px" id="r13c4"></td>
                  <td><input type="number" style="width:100px" id="r13c5"></td>
                  <td><input type="number" style="width:100px" id="r13c6"></td>
              </tr> 
              <tr>
                  <td>Carreteras libres</td>
                  <td><input type="number" style="width:100px" id="r4c1"></td>
                  <td><input type="number" style="width:100px" id="r4c2"></td>
                  <td><input type="number" style="width:100px" id="r4c3"></td>
                  <td><input type="number" style="width:100px" id="r4c4"></td>
                  <td><input type="number" style="width:100px" id="r4c5"></td>
                  <td><input type="number" style="width:100px" id="r4c6"></td>
              </tr>  
              <tr  style="background-color:#ccc">
                  <td>Carreteras de cuota <br> concesionadas a:</td>
                  <td><input type="number" style="width:100px" id="r3c1"></td>
                  <td><input type="number" style="width:100px" id="r3c2"></td>
                  <td><input type="number" style="width:100px" id="r3c3"></td>
                  <td><input type="number" style="width:100px" id="r3c4"></td>
                  <td><input type="number" style="width:100px" id="r3c5"></td>
                  <td><input type="number" style="width:100px" id="r3c6"></td>
              </tr> 
              <tr>
                  <td>CAPUFE y FONADIN</td>
                  <td><input type="number" style="width:100px" id="r2c1"></td>
                  <td><input type="number" style="width:100px" id="r2c2"></td>
                  <td><input type="number" style="width:100px" id="r2c3"></td>
                  <td><input type="number" style="width:100px" id="r2c4"></td>
                  <td><input type="number" style="width:100px" id="r2c5"></td>
                  <td><input type="number" style="width:100px" id="r2c6"></td>
              </tr> 
              <tr>
                  <td>Instituciones financieras</td>
                  <td><input type="number" style="width:100px" id="r8c1"></td>
                  <td><input type="number" style="width:100px" id="r8c2"></td>
                  <td><input type="number" style="width:100px" id="r8c3"></td>
                  <td><input type="number" style="width:100px" id="r8c4"></td>
                  <td><input type="number" style="width:100px" id="r8c5"></td>
                  <td><input type="number" style="width:100px" id="r8c6"></td>
              </tr>
              <tr>
                  <td>Iniciativa privada</td>
                  <td><input type="number" style="width:100px" id="r7c1"></td>
                  <td><input type="number" style="width:100px" id="r7c2"></td>
                  <td><input type="number" style="width:100px" id="r7c3"></td>
                  <td><input type="number" style="width:100px" id="r7c4"></td>
                  <td><input type="number" style="width:100px" id="r7c5"></td>
                  <td><input type="number" style="width:100px" id="r7c6"></td>
              </tr>
              <tr>
                  <td>Gobiernos estatales</td>
                  <td><input type="number" style="width:100px" id="r6c1"></td>
                  <td><input type="number" style="width:100px" id="r6c2"></td>
                  <td><input type="number" style="width:100px" id="r6c3"></td>
                  <td><input type="number" style="width:100px" id="r6c4"></td>
                  <td><input type="number" style="width:100px" id="r6c5"></td>
                  <td><input type="number" style="width:100px" id="r6c6"></td>
              </tr>              
              <tr>
                  <td>Estatales de cuota</td>
                  <td><input type="number" style="width:100px" id="r5c1"></td>
                  <td><input type="number" style="width:100px" id="r5c2"></td>
                  <td><input type="number" style="width:100px" id="r5c3"></td>
                  <td><input type="number" style="width:100px" id="r5c4"></td>
                  <td><input type="number" style="width:100px" id="r5c5"></td>
                  <td><input type="number" style="width:100px" id="r5c6"></td>
              </tr>
              <tr  style="background-color:#ccc">
                  <td>Red Alimentadora</td>
                  <td><input type="number" style="width:100px" id="r9c1"></td>
                  <td><input type="number" style="width:100px" id="r9c2"></td>
                  <td><input type="number" style="width:100px" id="r9c3"></td>
                  <td><input type="number" style="width:100px" id="r9c4"></td>
                  <td><input type="number" style="width:100px" id="r9c5"></td>
                  <td><input type="number" style="width:100px" id="r9c6"></td>
              </tr>
              <tr>
                  <td>Red alimentadora estatal</td>
                  <td><input type="number" style="width:100px" id="r10c1"></td>
                  <td><input type="number" style="width:100px" id="r10c2"></td>
                  <td><input type="number" style="width:100px" id="r10c3"></td>
                  <td><input type="number" style="width:100px" id="r10c4"></td>
                  <td><input type="number" style="width:100px" id="r10c5"></td>
                  <td><input type="number" style="width:100px" id="r10c6"></td>
              </tr>
              <tr>
                  <td>Red alimentadora SICT</td>
                  <td><input type="number" style="width:100px" id="r12c1"></td>
                  <td><input type="number" style="width:100px" id="r12c2"></td>
                  <td><input type="number" style="width:100px" id="r12c3"></td>
                  <td><input type="number" style="width:100px" id="r12c4"></td>
                  <td><input type="number" style="width:100px" id="r12c5"></td>
                  <td><input type="number" style="width:100px" id="r12c6"></td>
              </tr>
              <tr>
                  <td>Red alimentadora otros*</td>
                  <td><input type="number" style="width:100px" id="r11c1"></td>
                  <td><input type="number" style="width:100px" id="r11c2"></td>
                  <td><input type="number" style="width:100px" id="r11c3"></td>
                  <td><input type="number" style="width:100px" id="r11c4"></td>
                  <td><input type="number" style="width:100px" id="r11c5"></td>
                  <td><input type="number" style="width:100px" id="r11c6"></td>
              </tr>
              <tr  style="background-color:#ccc">
                  <td>Red rural</td>
                  <td><input type="number" style="width:100px" id="r14c1"></td>
                  <td><input type="number" style="width:100px" id="r14c2"></td>
                  <td><input type="number" style="width:100px" id="r14c3"></td>
                  <td><input type="number" style="width:100px" id="r14c4"></td>
                  <td><input type="number" style="width:100px" id="r14c5"></td>
                  <td><input type="number" style="width:100px" id="r14c6"></td>
              </tr>
              <tr>
                  <td>Red rural estatal</td>
                  <td><input type="number" style="width:100px" id="r15c1"></td>
                  <td><input type="number" style="width:100px" id="r15c2"></td>
                  <td><input type="number" style="width:100px" id="r15c3"></td>
                  <td><input type="number" style="width:100px" id="r15c4"></td>
                  <td><input type="number" style="width:100px" id="r15c5"></td>
                  <td><input type="number" style="width:100px" id="r15c6"></td>
              </tr>
              <tr>
                  <td>Red rural SICT</td>
                  <td><input type="number" style="width:100px" id="r17c1"></td>
                  <td><input type="number" style="width:100px" id="r17c2"></td>
                  <td><input type="number" style="width:100px" id="r17c3"></td>
                  <td><input type="number" style="width:100px" id="r17c4"></td>
                  <td><input type="number" style="width:100px" id="r17c5"></td>
                  <td><input type="number" style="width:100px" id="r17c6"></td>
              </tr>
              <tr>
                  <td>Red rural otros</td>
                  <td><input type="number" style="width:100px" id="r16c1"></td>
                  <td><input type="number" style="width:100px" id="r16c2"></td>
                  <td><input type="number" style="width:100px" id="r16c3"></td>
                  <td><input type="number" style="width:100px" id="r16c4"></td>
                  <td><input type="number" style="width:100px" id="r16c5"></td>
                  <td><input type="number" style="width:100px" id="r16c6"></td>
              </tr>
              <tr style="background-color:#ccc"> 
                  <td>Brechas</td>
                  <td><input type="number" style="width:100px" id="r1c1"></td>
                  <td><input type="number" style="width:100px" id="r1c2"></td>
                  <td><input type="number" style="width:100px" id="r1c3"></td>
                  <td><input type="number" style="width:100px" id="r1c4"></td>
                  <td><input type="number" style="width:100px" id="r1c5"></td>
                  <td><input type="number" style="width:100px" id="r1c6"></td>
              </tr> 
              <tr>
                  <td>TOTAL</td>
                  <td><input type="number" style="width:100px" id="r18c1"></td>
                  <td><input type="number" style="width:100px" id="r18c2"></td>
                  <td><input type="number" style="width:100px" id="r18c3"></td>
                  <td><input type="number" style="width:100px" id="r18c4"></td>
                  <td><input type="number" style="width:100px" id="r18c5"></td>
                  <td><input type="number" style="width:100px" id="r18c6"></td>
              </tr>
          </table>       
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnCerrarModalActInfo">Cerrar</button>
        <button type="button" class="btn btn-primary" @click="actInfoRedCarr">Actualiza información</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Exportación del tránsito vehicular en caminos y puentes de CAPUFE</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
          <center>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnCerrarModNvo">Cerrar</button>
        <button type="button" class="btn btn-primary" @click="exportaDatos">Exportar a Excel</button>
          </center>
      </div>
      <div class="modal-footer">
          &nbsp;
      </div>
    </div>
  </div>
</div>


</div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Resize,Page, CommandColumn, DetailRow, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
import { datacam } from './datasource.js';
import { datapte } from './datasourcepte.js';
import modalExtraordinarios from '@/components/presupuestos/ModalExtraordinarioscapufe';
import VueInfiniteAutocomplete from 'vue-infinite-autocomplete';
import ExportaXlsx from '@/components/ExportaXlsxCapufe';
import { consultaCarreteras, actualizaCarreteras, qryactualizaCarreteras } from '@/api/alta-camino'
import { Message } from 'element-ui'



Vue.use(GridPlugin);

const API = process.env.VUE_APP_SCT_SVC_BACK_BASE_URL;
let tmpdata = {}

export default {
    components: {
        modalExtraordinarios,
        "vue-infinite-autocomplete": VueInfiniteAutocomplete,        
    },
    data() {
        return {
            estado:'',
            isNormativo:true,
            anioBusqueda:'',
            anio:'',
            chosen: '',
            showModal:false,
            extraordinarios:[],
            //commands: [{ buttonOption: { content: "Edita", cssClass: "e-flat" }}],
            datacam: datacam,
            datapte: datapte,
            //editSettings: { allowEditing: false, allowAdding: false, allowDeleting: false }, 
            //toolbar: ['Edit'], 
            //commands: [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons', click: this.editTemplate }}],
            currentOptions: [
            { text: '2000', id: 1},
            { text: '2001', id: 2},
            { text: '2002', id: 3},
            { text: '2003', id: 4},
            { text: '2004', id: 5},
            { text: '2005', id: 6},
            { text: '2006', id: 7},
            { text: '2007', id: 8},
            { text: '2008', id: 9},
            { text: '2009', id: 10},
            { text: '2010', id: 11},
            { text: '2011', id: 12},
            { text: '2012', id: 13},
            { text: '2013', id: 14},
            { text: '2014', id: 15},
            { text: '2015', id: 16},
            { text: '2016', id: 17},
            { text: '2017', id: 18},
            { text: '2018', id: 19},
            { text: '2019', id: 20},
            { text: '2020', id: 21},
            { text: '2021', id: 22},
            { text: '2022', id: 23}
            ],


            childGrid: {

                queryString: 'anio', //<--- 
                //toolbar: ['Edit', 'Update', 'Cancel'],
                //editSettings: { allowEditing: true, allowAdding: false, allowDeleting: false },
                allowPaging:true,
                allowTextWrap:true,
                pageSettings:{ pageCount:8, pageSize: 4  },
                //actionBegin: this.actualizaRegistroRedCarr,
                rowSelected: this.FormularioActualizacion,
                columns: [
                   
                    { template:'<div><button class="btn btn-danger" data-toggle="modal" data-target="#exampleModal"><span class="glyphicon glyphicon-pencil"></span></button></div>', headerText:'Editar' , textAlign:'Center', width:150},                    
                    
                    { field: 'anio', headerText: 'anio', textAlign: 'Right', width: 120, visible:false },
                    { field: 'estado', headerText: 'Estado', textAlign: 'Center', width: 200 },
                    { field: 'cuota_p2c', headerText: '2 carriles', width: 150 },
                    { field: 'cuota_p4c', headerText: '4 carriles', width: 150 },
                    { field: 'cuota_pm4c', headerText: 'Más de 4 carriles', width: 200 },
                    { field: 'cuota_total', headerText: 'Total', width: 150 },

                    { field: 'libre_terra', headerText: 'Terracerías', width: 150 },
                    { field: 'libre_reve', headerText: 'Revestidas', width: 150 },
                    { field: 'libre_p2c', headerText: '2 carriles', width: 150 },
                    { field: 'libre_p4c', headerText: '4 carriles', width: 150 },
                    { field: 'libre_pm4c', headerText: 'Más de 4 carriles', width: 200 },
                    { field: 'libre_total', headerText: 'Total', width: 150 },

                    { field: 'alime_terra', headerText: 'Terracerías', width: 150 },
                    { field: 'alime_reve', headerText: 'Revestidas', width: 150 },
                    { field: 'alime_p2c', headerText: '2 carriles', width: 150 },
                    { field: 'alime_p4c', headerText: '4 carriles', width: 150 },
                    { field: 'alime_pm4c', headerText: 'Más de 4 carriles', width: 200 },
                    { field: 'alime_total', headerText: 'Total', width: 150 },

                    { field: 'rural_terra', headerText: 'Terracerías', width: 150 },
                    { field: 'rural_reve', headerText: 'Revestidas', width: 150 },
                    { field: 'rural_p2c', headerText: '2 carriles', width: 150 },
                    { field: 'rural_p4c', headerText: '4 carriles', width: 150 },
                    { field: 'rural_pm4c', headerText: 'Más de 4 carriles', width: 200 },
                    { field: 'rural_total', headerText: 'Total', width: 150 },

                    { field: 'brech_terra', headerText: 'Terracerías', width: 150 },
                    { field: 'brech_reve', headerText: 'Revestidas', width: 150 },
                    { field: 'brech_p2c', headerText: '2 carriles', width: 150 },
                    { field: 'brech_p4c', headerText: '4 carriles', width: 150 },
                    { field: 'brech_pm4c', headerText: 'Más de 4 carriles', width: 200 },
                    { field: 'brech_total', headerText: 'Total', width: 150 }
                ]
            },
            

            cuotaColumns: [
                {   field: 'cuota_p2c',headerText: '2 Carriles',width: 150,textAlign: 'Right',minWidth: 10},
                {   field: 'cuota_p4c',headerText: '4 Carriles',width: 150,textAlign: 'Right',minWidth: 10},
                {   field: 'cuota_pm4c',headerText: 'Más de 4 Carriles',width: 200,textAlign: 'Right',minWidth: 10},
                {   field: 'cuota_total',headerText: 'Total',width: 150,textAlign: 'Right',minWidth: 10}
            ],
            LibreColumns: [
                {   field: 'libre_terra',headerText: 'Terracerías',width: 150,minWidth: 10},
                {   field: 'libre_reve',headerText: 'Revestidas',width: 150,minWidth: 10},
                {   field: 'libre_p2c',headerText: '2 Carriles',width: 150,minWidth: 10},
                {   field: 'libre_p4c', headerText: '4 Carriles', width: 150, minWidth: 10 },
                {   field: 'libre_pm4c', headerText: 'Más de 4 Carriles', width: 200,minWidth: 10},
                {   field: 'libre_total',headerText: 'Total',width: 150,minWidth: 10}
            ],
            AlimColumns :[
                {   field: 'alime_terra',headerText: 'Terracerías',width: 150,minWidth: 10},
                {   field: 'alime_reve',headerText: 'Revestidas',width: 150,minWidth: 10},
                {   field: 'alime_p2c',headerText: '2 Carriles',width: 150,minWidth: 10},
                {   field: 'alime_p4c', headerText: '4 Carriles', width: 150, minWidth: 10 },
                {   field: 'alime_pm4c', headerText: 'Más de 4 Carriles', width: 150,minWidth: 10},
                {   field: 'alime_total',headerText: 'Total',width: 150,minWidth: 10}
            ],
            ruralesColumns :[
                {   field: 'rural_terra',headerText: 'Terracerías',width: 150,minWidth: 10},
                {   field: 'rural_reve',headerText: 'Revestidas',width: 150,minWidth: 10},
                {   field: 'rural_p2c',headerText: '2 Carriles',width: 150,minWidth: 10},
                {   field: 'rural_p4c', headerText: '4 Carriles', width: 150, minWidth: 10 },
                {   field: 'rural_pm4c', headerText: 'Más de 4 Carriles', width: 200,minWidth: 10},
                {   field: 'rural_total',headerText: 'Total',width: 150,minWidth: 10}
            ],
            brechasColumns:[
                {   field: 'brech_terra',headerText: 'Terracerías',width: 150,minWidth: 10},
                {   field: 'brech_reve',headerText: 'Revestidas',width: 150,minWidth: 10},
                {   field: 'brech_p2c',headerText: '2 Carriles',width: 150,minWidth: 10},
                {   field: 'brech_p4c', headerText: '4 Carriles', width: 150, minWidth: 10 },
                {   field: 'brech_pm4c', headerText: 'Más de 4 Carriles', width: 200,minWidth: 10},
                {   field: 'brech_total',headerText: 'Total',width: 150,minWidth: 10}
            ]
    };
  },
  methods: { 

      async FormularioActualizacion(args)
      {
        this.anio = args.data.anio
        this.estado = args.data.estado
        console.log(args.data.anio)
        console.log(args.data.estado)
        
        $('#exampleModalLabel').html('Actualización de información: ' + args.data.estado + ' - ' + args.data.anio)
        try{
            let datosRC = await qryactualizaCarreteras(args.data.anio,args.data.estado)
            let ren = 0;
            for (let i = 0; i < datosRC.data.length; i++)
            {
                if (datosRC.data.length === 18)
                {
                    $('#r'+(i+1)+'c1').val(datosRC.data[i].c1)
                    $('#r'+(i+1)+'c2').val(datosRC.data[i].c2)
                    $('#r'+(i+1)+'c3').val(datosRC.data[i].c3)
                    $('#r'+(i+1)+'c4').val(datosRC.data[i].c4)
                    $('#r'+(i+1)+'c5').val(datosRC.data[i].c5)
                    $('#r'+(i+1)+'c6').val(datosRC.data[i].c6)
                }
                else
                {
                    if (ren === 11)   
                    {
                        $('#r'+(ren+1)+'c1').val(null)
                        $('#r'+(ren+1)+'c2').val(null)
                        $('#r'+(ren+1)+'c3').val(null)
                        $('#r'+(ren+1)+'c4').val(null)
                        $('#r'+(ren+1)+'c5').val(null)
                        $('#r'+(ren+1)+'c6').val(null)
                        ren++
                    }
                    $('#r'+(ren+1)+'c1').val(datosRC.data[i].c1)
                    $('#r'+(ren+1)+'c2').val(datosRC.data[i].c2)
                    $('#r'+(ren+1)+'c3').val(datosRC.data[i].c3)
                    $('#r'+(ren+1)+'c4').val(datosRC.data[i].c4)
                    $('#r'+(ren+1)+'c5').val(datosRC.data[i].c5)
                    $('#r'+(ren+1)+'c6').val(datosRC.data[i].c6)
                    ren++
                }
            }
        }
        catch(error)
        {
            alert(error)
        }
        //alert('row index: ' + args.row.getAttribute('aria-rowindex'));
        //alert('column index: ' + args.target.getAttribute('aria-colindex'));                      
      },

      actInfoRedCarr()
      {
          try{
            let frmRedCarEdo ={}
            let ren=1,col=1,cel='',valor = 0.0;

            frmRedCarEdo["anio"]=this.anio
            frmRedCarEdo["estado"]=this.estado

            for ( ren=1; ren <=18; ren++)
                for(col=1; col <=6;  col++)
                {
                    cel = 'r'+ren+'c'+col
                    valor =  ( $('#'+cel).val() ==='') ? null : $('#'+cel).val()
                    frmRedCarEdo[cel] = valor
                }

            let renv = actualizaCarreteras(frmRedCarEdo)
            Message({
                    message: 'Se actualizó correctamente la informacion',
                    type: 'success', 
                    duration: 3 * 1000
                })
            $('#btnCerrarModalActInfo').click()
            $('#btnBuscarRedCarr').click()

          }
          catch(error)
          {
              alert(error)
          }
        
            
          
      },

      async actualizaRegistroRedCarr(args)
      {
          console.log('---------------------------------------------------------------')
          console.log(args.requestType)
          if (args.requestType === "beginEdit")
            {
                tmpdata.data = args.data
                console.log (args.data)
                console.log(tmpdata.data)
            }
            else if (args.requestType === "save")
            {
                console.log("IGUAL:" + (tmpdata.data === args.data))
                console.log(tmpdata.data)
                try{
                    let resp = await actualizaCarreteras(args.data)
                    Message({
                            message: 'Se actualizó correctamente la informacion',
                            type: 'success', 
                            duration: 3 * 1000
                        })
                }
                catch(error)
                {
                    Message({
                            message: error.Message,
                            type: 'error', 
                            duration: 3 * 1000
                        })
                }

                console.log('------------------------------------------------------------------')
                console.log(args.data.estado)
                console.log(args.data.anio)
            }                
      },

      exportaDatos(){
          if ( this.anioBusqueda==='') 
          {
              alert('Debe indicar el año') 
              return 
          }
          top.location = API + '/consultaredcarr/' + this.anioBusqueda
          //$('#btnCerrarModNvo').click()
      },

//      rowSelected: function(args) {
//        alert("row index: "+args.row.getAttribute('aria-rowindex'));
//        alert("column index: "+args.target.getAttribute('aria-colindex'));
//    },
        miFuncion()
        {
            alert('hola')
        },
      async onDetailDataBound(args) {

          try{
              let param ={"anio":args.data.anio,"tipo":"entidades"}
              let danio = await consultaCarreteras(param) 
              args.detailElement.querySelector('.e-grid').ej2_instances[0].dataSource = danio.data; // assign data source for child grid.
          }
          catch(error)
          {
              alert(error)
          }

        
      },
      async consultaRedCarr()
      {
          try{
            let param ={"anio":this.anioBusqueda,"tipo":"global"}
            let datos = await consultaCarreteras(param)  
            this.$refs.grid.ej2Instances.dataSource = datos.data  
            

            if ( datos.data.length === 0)
            {
                Message({
                            message: 'NO EXISTEN DATOS PARA EL PERIODO SELECCIONADO:' + (parseInt(this.anioBusqueda)-9) + '-' + this.anioBusqueda,
                            type: 'error', 
                            duration: 4 * 1000
                        })
            }
          }
          catch(error)
          {
              alert(JSON.stringify(error))
          }
      },
        handleOnSelect(selectedValue) 
        {
            this.anioBusqueda = parseInt(selectedValue.text)
        }
        ,
      exportTemplate () {           
            return { 
                template:ExportaXlsx                
            }
        },

        miTemplate(){
            return{
                template:''
            }
        }
  }, 
  provide: {
    grid: [Resize, Page,CommandColumn, DetailRow, Edit, Toolbar]
    /*treegrid: [Page, Aggregate, Resize]*/
  },
    addExtraordinario() {
            this.showModal = true
            this.showError = false
            
    },
}
</script>
<style>
        .e-grid .e-icon-grightarrow::before,
        .e-grid-menu .e-icon-grightarrow::before {
            content: '\e7f9';
        }
    
        .e-grid .e-icon-gdownarrow::before,
        .e-grid-menu .e-icon-gdownarrow::before {
            content: '\e934';
        }
</style>