<template>
    <div> 
        <button class="btn btn-primary btn-sm" type="button" aria-label="Editar datos"
                data-toggle="modal"
                data-target="#modelExportaDIXlsx"
                @click="setId(data)"
                :disabled="data.isCanceled"
                >
            <span class="glyphicon glyphicon-cloud-download" aria-hidden="true"></span>
        </button>
        <div class="modal fade" ref="modelExportaDIXlsx" id="modelExportaDIXlsx" tabindex="-1" role="dialog" aria-labelledby="modelExportaDIXlsx"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="titmod1"> </h4>
                    </div>
                    <div class="modal-body">   
 
                        <div class="col-sm-12">        
                            <button type="button" id="cierraEditObra" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary" @click="exportaDatos">Exportar a Excel</button>                 
                        </div>      
                        <br><br><br><br><br>&nbsp;            
                                  
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

const API = process.env.VUE_APP_SCT_SVC_BACK_BASE_URL;

let EdtFrmPf = {}
let anio = 0
export default {
    name:'ExportaXlsx',
    data() { 
        return {   
         
        };
    },
    methods:{
        setId(data) {
            anio = data.anio
            $('#titmod1').html('Exportación del tránsito en puentes de CAPUFE  (' + anio + ')')
        },
        exportaDatos(){
            top.location = API + '/consultaredcarr/' + anio
        },
    }
    
}
</script>

<style scoped>

</style>