import Vue from 'vue'
import VueRouter from 'vue-router'
import BusquedaCaminos from '../views/BusquedaCaminos.vue'
import Presupuestos from '../views/Presupuestos.vue'
import AltaCamino from '../views/AltaCaminosWork.vue'
import catIC from '../views/catICWork.vue'
import catTAT from '../views/catTATWork.vue'
import catTF from '../views/catTFWork.vue'
import catTAA from '../views/catTAAWork.vue'
import redCarr from '../components/redCarr/DatosRedCarretera.vue'
import tablero from '../components/tablero/Datostablero.vue'
import transcapufe from '../components/capufe/DatosRedCapufe.vue'
import AnalysisWork from '../components/AnalysisWork.vue'
import convenio from '../components/convenio.vue'
import financieros from '../components/AltaDeCaminos/DatosFinancieros.vue'
import Login from '../views/Login.vue'
import store from "../store/index";
import GridResultObra from "../views/GridResultBusqueda.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    //meta: { guest: true },
  }, 
  {
    path: '/tablero',
    name: 'tablero',
    component: tablero,
    meta: { requiresAuth: true }
    //meta: { guest: true },
  },
  {
    path: '/redCarr',
    name: 'redCarr',
    component: redCarr,
    meta: { requiresAuth: true }
    //meta: { guest: true },
  }, 
    
  {
    path: '/transcapufe',
    name: 'transcapufe',
    component: transcapufe,
    meta: { requiresAuth: true }
    //meta: { guest: true },
  },  

  
    
  {
    path: '/catIC',
    name: 'catIC',
    component: catIC,
    meta: { requiresAuth: true }
    //meta: { guest: true },
  },  
  {
    path: '/catTAT',
    name: 'catTAT',
    component: catTAT,
    meta: { requiresAuth: true }
    //meta: { guest: true },
  },  
  {
    path: '/catTF',
    name: 'catTF',
    component: catTF,
    meta: { requiresAuth: true }
    //meta: { guest: true },
  },  
  {
    path: '/catTAA',
    name: 'catTAA',
    component: catTAA,
    meta: { requiresAuth: true }
    //meta: { guest: true },
  },  
   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let isAuthenticated = store.state.login.isAuthenticated

  const accessToken = localStorage.getItem('dgp-auth-access-token')
  const refreshToken = localStorage.getItem('dgp-auth-refresh-token')
  if (accessToken && refreshToken) {
      isAuthenticated = true
  }

  if (requiresAuth && !isAuthenticated) {
    next('/')
  } else {
    next()
  }
})


export default router
