var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mx-auto"},[_c('div',{staticClass:"row"},[_c('h3',[_vm._v("Análisis de Obra")]),_c('hr',{staticClass:"red"}),_c('form',{attrs:{"role":"form"}},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"control-label",attrs:{"for":"estado"}},[_vm._v("Estado")]),_c('ejs-combobox',{attrs:{"dataSource":_vm.estadosData,"fields":_vm.estadosFields,"placeholder":"Selecciona un estado","change":_vm.obtenerMunicipios,"enabled":_vm.estadosHabilitado},model:{value:(_vm.icveEstadoInegi),callback:function ($$v) {_vm.icveEstadoInegi=$$v},expression:"icveEstadoInegi"}})],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"control-label",attrs:{"for":"municipio"}},[_vm._v("Municipio")]),_c('ejs-combobox',{attrs:{"dataSource":_vm.municipiosData,"fields":_vm.municipiosFields,"placeholder":"Selecciona un municipio","change":_vm.obtenerLocalidades,"enabled":_vm.municipiosHabilitado},model:{value:(_vm.icveMunicipio),callback:function ($$v) {_vm.icveMunicipio=$$v},expression:"icveMunicipio"}})],1),_vm._m(0)]),_c('h4',[_vm._v("2020")]),_c('hr',{staticClass:"red"}),_vm._m(1),_vm._m(2),_vm._m(3),_c('hr',{staticClass:"red"}),_c('h4',[_vm._v("Conceptos")]),_vm._m(4),_vm._m(5),_c('h4',[_vm._v("Conceptos Extraordinarios")]),_vm._m(6),_vm._m(7)])]),_vm._m(8),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"control-label",attrs:{"for":"Obra"}},[_vm._v("Obra")]),_c('select',{staticClass:"form-control",attrs:{"id":"Obra"}},[_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"region"}},[_vm._v("Monto")]),_c('input',{staticClass:"form-control",attrs:{"id":"region","placeholder":"$","type":"text"}})]),_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"Obra"}},[_vm._v("Meta")]),_c('select',{staticClass:"form-control",attrs:{"id":"Obra"}},[_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")]),_c('option',[_vm._v("xxxxxxxxx")])])]),_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"region"}},[_vm._v("Ancho")]),_c('input',{staticClass:"form-control",attrs:{"id":"region","placeholder":"$","type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"region"}},[_vm._v("Del KM")]),_c('input',{staticClass:"form-control",attrs:{"id":"region","placeholder":"$","type":"text"}})]),_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"region"}},[_vm._v("Al KM")]),_c('input',{staticClass:"form-control",attrs:{"id":"region","placeholder":"$","type":"text"}})]),_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"region"}},[_vm._v("Número de empleos")]),_c('input',{staticClass:"form-control",attrs:{"id":"region","placeholder":"$","type":"number"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"region"}},[_vm._v("Fecha inicio")]),_c('input',{staticClass:"form-control",attrs:{"id":"region","placeholder":"$","type":"text"}})]),_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"control-label",attrs:{"for":"region"}},[_vm._v("Fecha Fin")]),_c('input',{staticClass:"form-control",attrs:{"id":"region","placeholder":"$","type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("First")]),_c('th',[_vm._v("Last")]),_c('th',[_vm._v("Handle")]),_c('th',[_vm._v("Handle")]),_c('th',[_vm._v("Cant. Total")]),_c('th',[_vm._v("P. Unitario")]),_c('th',[_vm._v("Importe Total")]),_c('th',[_vm._v("Importe Total")]),_c('th',[_vm._v("Ponderados")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Mark")]),_c('td',[_vm._v("Otto")]),_c('td',[_vm._v("@mdo")]),_c('td',[_vm._v("@mdo")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])]),_c('tr',[_c('td',[_vm._v("Jacob")]),_c('td',[_vm._v("Thornton")]),_c('td',[_vm._v("@fat")]),_c('td',[_vm._v("@fat")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])]),_c('tr',[_c('td',[_vm._v("Larry")]),_c('td',[_vm._v("the Bird")]),_c('td',[_vm._v("@twitter")]),_c('td',[_vm._v("@twitter")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('button',{staticClass:"btn btn-default pull-right vertical-buffer",attrs:{"type":"button","data-toggle":"modal","data-target":"#addConcept"}},[_vm._v(" Agregar Concepto ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("First")]),_c('th',[_vm._v("Last")]),_c('th',[_vm._v("Handle")]),_c('th',[_vm._v("Handle")]),_c('th',[_vm._v("Cant. Total")]),_c('th',[_vm._v("P. Unitario")]),_c('th',[_vm._v("Importe Total")]),_c('th',[_vm._v("Importe Total")]),_c('th',[_vm._v("Ponderados")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Mark")]),_c('td',[_vm._v("Otto")]),_c('td',[_vm._v("@mdo")]),_c('td',[_vm._v("@mdo")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])]),_c('tr',[_c('td',[_vm._v("Jacob")]),_c('td',[_vm._v("Thornton")]),_c('td',[_vm._v("@fat")]),_c('td',[_vm._v("@fat")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])]),_c('tr',[_c('td',[_vm._v("Larry")]),_c('td',[_vm._v("the Bird")]),_c('td',[_vm._v("@twitter")]),_c('td',[_vm._v("@twitter")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row vertical-buffer"},[_c('button',{staticClass:"btn btn-default pull-right",attrs:{"type":"button","data-toggle":"modal","data-target":"#error"}},[_vm._v(" Agregar Concepto ")]),_c('button',{staticClass:"btn btn-danger pull-right",attrs:{"type":"button"}},[_vm._v("Cancelar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"addConcept","tabindex":"-1","role":"dialog","aria-labelledby":"addConcept","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Ejemplo de ventana modal")])]),_c('div',{staticClass:"modal-body"},[_c('p',[_vm._v("Ejemplo de texto interno.")])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("Cerrar")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v("Guardar")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"error","tabindex":"-1","role":"dialog","aria-labelledby":"error","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Alerta")])]),_c('div',{staticClass:"modal-body"},[_c('p',[_vm._v("Las cantidades no coinciden.")])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("Cerrar")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v("Guardar")])])])])])
}]

export { render, staticRenderFns }